import { Flex, Link, Modal, ModalBody, ModalContent, ModalOverlay, useDisclosure } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import Image from 'next/image'
import { useState, VFC } from 'react'

export type MenuViewProps = {
    menuItems: MenuType[]
    isMenuOpen: boolean
    onClickOpenMenu: () => void
    onClickCloseMenu: () => void
    onClose: () => void
}

export const MenuView: VFC<MenuViewProps> = ({ menuItems, isMenuOpen, onClickOpenMenu, onClickCloseMenu, onClose }) => {
    return (
        <>
            {isMenuOpen ? (
                <Modal isOpen={isMenuOpen} isCentered onOverlayClick={onClickCloseMenu} onClose={onClose}>
                    <ModalOverlay bg="linear-gradient(180deg, rgba(45, 22, 39, 0.6) 0%, rgba(11, 5, 45, 0.6) 100%)" />
                    <ModalContent bg="tranceparent" shadow="none" w="700px">
                        <motion.div
                            animate={{ opacity: 1, scale: 1 }}
                            initial={{ opacity: 0, scale: 0 }}
                            transition={{ duration: 0.5 }}
                            className="fixed top-4 right-4 sm:top-10 sm:right-10 w-12 h-12 sm:w-18 sm:h-18 p-4 cursor-pointer"
                            onClick={onClickCloseMenu}>
                            <Image src="/images/hamburger_close.svg" alt="メニュー" layout="fill" objectFit="contain" />
                        </motion.div>
                        <ModalBody>
                            <Flex flexDirection="column" alignItems="center">
                                <Link href="/" className="relative w-full h-48" _focus={{ border: 'none' }}>
                                    <Image
                                        src="/images/logo.svg"
                                        alt="惑星（ほし）のさみだれ"
                                        layout="fill"
                                        objectFit="contain"
                                    />
                                </Link>
                                {menuItems.map((menu: MenuType, i: number) => (
                                    <Link
                                        key={i}
                                        href={menu.path}
                                        target={menu.newtab ? '_blank' : ''}
                                        className="relative w-full h-12 m-3">
                                        <Image src={menu.img_path} alt={menu.label} layout="fill" objectFit="contain" />
                                    </Link>
                                ))}
                            </Flex>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            ) : (
                <motion.div
                    animate={{ opacity: 1, scale: 1 }}
                    initial={{ opacity: 0, scale: 0 }}
                    transition={{ duration: 0.5 }}
                    className="fixed top-4 right-4 sm:top-10 sm:right-10 w-12 h-12 sm:w-18 sm:h-18 p-4 cursor-pointer z-10"
                    onClick={onClickOpenMenu}>
                    <Image src="/images/hamburger.svg" alt="メニュー" layout="fill" objectFit="contain" />
                </motion.div>
            )}
        </>
    )
}

export type MenuType = {
    label: string
    path: string
    img_path: string
    newtab: boolean
}

export type MenuProps = {}

export const Menu: VFC<MenuProps> = () => {
    const { onClose } = useDisclosure()
    const [isMenuOpen, setMenuOpen] = useState(false)
    const onClickOpenMenu = () => setMenuOpen(true)
    const onClickCloseMenu = () => setMenuOpen(false)

    const menuItems: MenuType[] = [
        {
            label: 'NEWS',
            path: '/news',
            img_path: '/images/title/news.svg',
            newtab: false,
        },
        {
            label: 'STORY',
            path: '/story',
            img_path: '/images/title/story.svg',
            newtab: false,
        },
        {
            label: 'ONAIR',
            path: '/onair',
            img_path: '/images/title/onair-broadcast.svg',
            newtab: false,
        },
        {
            label: 'MEMBER',
            path: '/member',
            img_path: '/images/title/member.svg',
            newtab: false,
        },
        {
            label: 'CAST',
            path: '/cast',
            img_path: '/images/title/cast.svg',
            newtab: false,
        },
        {
            label: 'MUSIC',
            path: '/music',
            img_path: '/images/title/music.svg',
            newtab: false,
        },
        {
            label: 'DISC',
            path: '/disc',
            img_path: '/images/title/disc.svg',
            newtab: false,
        },
        // {
        //     label: 'GOODS',
        //     path: '/goods',
        //     img_path: '/images/title/goods.svg',
        //     newtab: false,
        // },
        {
            label: 'Twitter',
            path: 'https://twitter.com/AnimeSamidare',
            img_path: '/images/title/twitter.svg',
            newtab: true,
        },
    ]

    return (
        <MenuView
            menuItems={menuItems}
            isMenuOpen={isMenuOpen}
            onClickOpenMenu={onClickOpenMenu}
            onClickCloseMenu={onClickCloseMenu}
            onClose={onClose}
        />
    )
}

export default Menu
