import '~/styles/tailwind.css'
import '~/styles/globals.css'
import '~/styles/markdown.css'

import { ChakraProvider } from '@chakra-ui/react'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { QueryClient, QueryClientProvider } from 'react-query'
import { RecoilRoot } from 'recoil'

import Menu from '~/components/global/CMenu'
import { useVh } from '~/hooks/useVh'
import { GA_TRACKING_ID, usePageView } from '~/libs/gtag'
import { customTheme } from '~/utils/chakra-theme'

function App({ Component, pageProps }: AppProps) {
    const queryClient = new QueryClient()
    const { VhVariable } = useVh()
    usePageView()

    return (
        <>
            <Head>
                <script async src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`} />
                <script
                    dangerouslySetInnerHTML={{
                        __html: `
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', '${GA_TRACKING_ID}', {
                        page_path: window.location.pathname,
                        });
                    `,
                    }}
                />
            </Head>
            <RecoilRoot>
                <QueryClientProvider client={queryClient}>
                    <ChakraProvider resetCSS={true} theme={customTheme}>
                        <Menu />
                        {VhVariable}
                        <Component {...pageProps} />
                    </ChakraProvider>
                </QueryClientProvider>
            </RecoilRoot>
        </>
    )
}

export default App
