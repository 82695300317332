import { extendTheme } from '@chakra-ui/react'

export const customTheme = extendTheme({
    colors: {
        main: '#0077C7',
        secondary: '#6FA6CC',
        white: '#FFFFFF',
        danger: '#E01E5A',
        warning: '#FF8800',
        text: {
            black: '#23221F',
            grey: '#706D65',
            link: '#0071C1',
        },
    },
})
