import { useRouter } from 'next/router'
import { useEffect } from 'react'

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID || ''

// hooks
export const usePageView = () => {
    const router = useRouter()

    useEffect(() => {
        if (!GA_TRACKING_ID) return
        router.events.on('routeChangeComplete', (url) => pageView(url))

        return () => {
            router.events.off('routeChangeComplete', (url) => pageView(url))
        }
    }, [router.events])
}

// PV 測定
const pageView = (url: string): void => {
    if (!GA_TRACKING_ID) return
    window.gtag('config', GA_TRACKING_ID, {
        page_path: url,
    })
}
